import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdvComponent } from './components/adv.component';
import { CanAuthenticationGuard } from './app.authguard';


const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  component: AdvComponent,
  canActivate: [CanAuthenticationGuard],
  data: { roles: ['ARZT', 'NETZMANAGER'] }
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanAuthenticationGuard]
})
export class AppRoutingModule { }
