import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalsService } from '../globals.service';

@Injectable()
export class AdvService {

  constructor(private globalsService: GlobalsService, private http: HttpClient) {}

  submitAdv(name: string, email: string, berechtigt: boolean, akzeptiert: boolean, emailUpdates: boolean) {
    return this.http.post<Object>(this.globalsService.baseHref + '/profil/acceptAdv', {
      name: name,
      email: email,
      berechtigt: berechtigt,
      akzeptiert: akzeptiert,
      emailUpdates: emailUpdates
    });
  }

  getAvvFilename() {
    return this.http.get<any>(this.globalsService.baseHref + '/netze/getAvvFilename');
  }

}
