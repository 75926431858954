import { Component, OnInit, Sanitizer, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalsService } from '../globals.service';
import { AdvService } from '../services/adv.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SwalService } from '../swal.service';


@Component({
  moduleId: 'netzmanager',
  templateUrl: '../templates/adv.component.html',
  providers: [AdvService]
})

export class AdvComponent implements OnInit {

  activeIndex = 0;
  name = '';
  email = '';
  berechtigt = false;
  akzeptiert = false;
  emailUpdates = false;

  trustedAvvUrl;


  constructor(
    public globalsService: GlobalsService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private advService: AdvService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.globalsService.disableLoading();
      let avvFilename = 'avv.pdf';

      this.advService.getAvvFilename().subscribe((response) => {
        avvFilename = response['avvFilename'];
        console.log('avvFilename', avvFilename);
        this.trustedAvvUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + encodeURIComponent(avvFilename));
      }, (err) => {
        this.trustedAvvUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + encodeURIComponent(avvFilename));
      });
    });
  }

  gotoNextStep() {
    this.activeIndex++;
  }

  gotoPreviousStep() {
    if (this.activeIndex === 0) { return; }
    this.activeIndex--;
  }

  submitAdv() {
    this.globalsService.enableLoading();
    this.advService.submitAdv(this.name, this.email, this.berechtigt, this.akzeptiert, this.emailUpdates).subscribe((status) => {
      this.globalsService.disableLoading();
      this.gotoNextStep();
    }, (err) => {
      this.globalsService.disableLoading();
      this.swalService.showWarning('Die Dokumente konnten nicht an Ihre E-Mail-Adresse gesendet werden. Bitte überpüfen Sie diese und versuchen Sie ggf. eine alternative Adresse.');
    });
  }

  closeWindow() {
    window.close();
  }
}
