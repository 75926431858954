import { Injectable } from '@angular/core';

@Injectable()
export class GlobalsService {
    baseHref = 'https://gw01.nm.mediquu.de';

    loading = false;
    loadingMessage = 'Bitte warten...';
    netzConfig = {};

    constructor() {}

    enableLoading() {
        this.loading = true;
    }

    disableLoading() {
        this.loading = false;
    }
}
