import { Injectable } from '@angular/core';
declare var Swal: any;

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor() { }

  showSuccess(text = ""): Promise<any> {
    return Swal.fire({
      title: '',
      text: text,
      icon: 'success',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    });
  }

  showInfo(text = ""): Promise<any> {
    return Swal.fire({
      title: '',
      text: text,
      icon: 'info',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    });
  }

  showWarning(text = "", confirmButtonText = 'OK'): Promise<any> {
    return Swal.fire({
      title: '',
      text: text,
      icon: 'warning',
      confirmButtonText: confirmButtonText,
      allowOutsideClick: false
    });
  }

  showConfirm(text = "", confirmButtonText = "OK", cancelButtonText = "Abbrechen") {
    return new Promise<void>((resolve, reject) => {
      Swal.fire({
        title: '',
        text: text,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  showPrompt(text = "", confirmButtonText = "OK", cancelButtonText = "Abbrechen") {
    return new Promise<string>((resolve, reject) => {
      Swal.fire({
        text: text,
        input: 'text',
        inputLabel: '',
        inputPlaceholder: '',
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off'
        },
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: confirmButtonText,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) { 
          resolve(result.value); 
        } else {
          reject();
        }
      });
    });
  }

  requestPassword(text = "", confirmButtonText = "OK", cancelButtonText = "") {
    return new Promise<string>((resolve, reject) => {
      Swal.fire({
        text: text,
        input: 'password',
        inputLabel: '',
        inputPlaceholder: '',
        inputAttributes: {
          autocapitalize: 'off',
          autocorrect: 'off'
        },
        showCancelButton: cancelButtonText !== '',
        cancelButtonText: cancelButtonText,
        confirmButtonText: confirmButtonText,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) { 
          resolve(result.value); 
        } else {
          reject();
        }
      });
    });
  }
}
